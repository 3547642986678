<template>
  <div class="relative file-card rounded bg-white" :class="`${currentFile?.id === file?.id ? 'cursor-not-allowed' : 'cursor-pointer'}`" @click="handleClick()">
    <div class="py-8 w-full text-center flex items-center justify-center" :class="`${currentFile?.id === file?.id ? 'bg-neutral-250' : 'bg-neutral-100'}`">
      <VideoPlay color="neutral-400" v-if="setIconsFile(file.mimeType) === 'VIDEO'" />
      <File color="neutral-400" width="55" height="55" v-else-if="setIconsFile(file.mimeType) === 'FOLDER'" />
      <Document color="neutral-400" v-else />
    </div>
    <div class="py-3 px-4 w-full border-t flex items-start justify-between" :class="[selected ? 'bg-yellow-secondary' : `${currentFile?.id === file.id ? 'bg-neutral-250' : 'bg-white'}`]">
      <div class="">
        <p class="title" :class="{ 'text-yellow-primary font-medium': selected }">
          {{ file.title }}
        </p>
        <p class="text-xs text-neutral-400">
          {{ formatDate(file.createdDate) }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  components: {
    VideoPlay: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/VideoPlay'),
    Document: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/Document'),
    EllipsisVertical: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/EllipsisVertical'),
    File: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/File')
  },
  props: {
    file: Object,
    option: Boolean,
    index: Number,
    selected: Boolean,
    fileType: String,
    currentFile: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      visibleOption: false,
      tabindex: 0
    }
  },
  methods: {
    isVideo(type) {
      return type.includes('video')
    },
    formatDate(date) {
      return moment(date).local().format('DD MMM YYYY HH:mm')
    },
    handleClick() {
      if (this.currentFile?.id === this.file.id) return
      if (this.file.mimeType === 'FOLDER') {
        this.selectFile()
        return
      }
      if (this.setIconsFile(this.file.mimeType) !== this.fileType) {
        this.selectFile()
        this.$emit('handleErrorMessage', `The selected file must be in ${this.fileType.toLowerCase()} format`)
        return
      }

      if (this.setIconsFile(this.file.mimeType) === 'DOCUMENT') {
        this.isPdf(this.file.mimeType)
        return
      }

      this.selectFile()
      this.$emit('handleErrorMessage', '')
    },
    isPdf(type) {
      if (type.includes('pdf')) {
        this.selectFile()
        this.$emit('handleErrorMessage', '')
        return
      }
      this.selectFile()
      this.$emit('handleErrorMessage', 'The document must be in PDF format')
    },
    selectFile() {
      this.$emit('handleClick', this.file, this.index)
    },
    setIconsFile(type) {
      if (type.includes('video')) return 'VIDEO'
      if (type === 'FOLDER') return 'FOLDER'
      return 'DOCUMENT'
    }
  }
}
</script>
<style lang="scss" scoped>
.rounded-lg {
  border-radius: 8px !important;
}
.option {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
}
.file-card {
  box-shadow: 0px 2px 4px rgba(39, 40, 51, 0.12);
}
.px-4 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.py-3 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
.p-3 {
  padding: 12px !important;
}
.p-5 {
  padding: 1.25rem !important;
}
.p-4 {
  padding: 1rem !important;
}
p {
  margin: 0;
}
a {
  color: #58595b;
}
button {
  margin: 0 !important;
  --tw-border-opacity: 1;
  border-color: rgba(253, 233, 210, var(--tw-border-opacity)) !important;
  &:hover {
    background: #fde9d2;
  }
}
.title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 4px;
  line-height: 1.25;
  height: 35px;
  word-break: break-all;
}
</style>
