var render = function () {
  var _vm$currentFile, _vm$file, _vm$currentFile2, _vm$file2, _vm$currentFile3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "relative file-card rounded bg-white",
    class: "".concat(((_vm$currentFile = _vm.currentFile) === null || _vm$currentFile === void 0 ? void 0 : _vm$currentFile.id) === ((_vm$file = _vm.file) === null || _vm$file === void 0 ? void 0 : _vm$file.id) ? 'cursor-not-allowed' : 'cursor-pointer'),
    on: {
      "click": function click($event) {
        return _vm.handleClick();
      }
    }
  }, [_c('div', {
    staticClass: "py-8 w-full text-center flex items-center justify-center",
    class: "".concat(((_vm$currentFile2 = _vm.currentFile) === null || _vm$currentFile2 === void 0 ? void 0 : _vm$currentFile2.id) === ((_vm$file2 = _vm.file) === null || _vm$file2 === void 0 ? void 0 : _vm$file2.id) ? 'bg-neutral-250' : 'bg-neutral-100')
  }, [_vm.setIconsFile(_vm.file.mimeType) === 'VIDEO' ? _c('VideoPlay', {
    attrs: {
      "color": "neutral-400"
    }
  }) : _vm.setIconsFile(_vm.file.mimeType) === 'FOLDER' ? _c('File', {
    attrs: {
      "color": "neutral-400",
      "width": "55",
      "height": "55"
    }
  }) : _c('Document', {
    attrs: {
      "color": "neutral-400"
    }
  })], 1), _c('div', {
    staticClass: "py-3 px-4 w-full border-t flex items-start justify-between",
    class: [_vm.selected ? 'bg-yellow-secondary' : "".concat(((_vm$currentFile3 = _vm.currentFile) === null || _vm$currentFile3 === void 0 ? void 0 : _vm$currentFile3.id) === _vm.file.id ? 'bg-neutral-250' : 'bg-white')]
  }, [_c('div', {}, [_c('p', {
    staticClass: "title",
    class: {
      'text-yellow-primary font-medium': _vm.selected
    }
  }, [_vm._v(" " + _vm._s(_vm.file.title) + " ")]), _c('p', {
    staticClass: "text-xs text-neutral-400"
  }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.file.createdDate)) + " ")])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }